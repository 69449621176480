import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

/**
 * PM (19.06.2019) @see https://angular.io/guide/http
 */
import { HttpClientModule } from '@angular/common/http';
//import { HttpClientJsonpModule} from "@angular/common/http";


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {AdminComponent} from "./pages/admin/admin.component";
import {ArticlesComponent, ArticlesAdminComponent} from "./pages/articles/articles.component";
import {NavComponent} from './sections/nav/nav.component';
import {FooterComponent} from './sections/footer/footer.component';
import {ModalComponent} from './sections/modal/modal.component';
import {MatomoComponent} from './sections/matomo/matomo.component';
import {FormsModule} from "@angular/forms";


@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    ArticlesComponent,
    ArticlesAdminComponent,
    NavComponent,
    FooterComponent,
    ModalComponent,
    MatomoComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
