import {Component} from '@angular/core';
import {Section} from '../../utils/Section';

@Component({
  selector: 'section-matomo',
  templateUrl: './matomo.component.html',
  //styleUrls: ['./modal.component.less']
})

/**
 * @see https://codecraft.tv/courses/angular/quickstart/nesting-components-and-inputs/
 */
export class MatomoComponent extends Section {
  slug = 'matomo';
}
