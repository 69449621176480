import {Component, ViewEncapsulation} from '@angular/core';
import * as $ from 'jquery';
import {Page} from '../../utils/Page';
import {LanguageService} from '../../utils/language.service';
import {HttpClient} from '@angular/common/http';
import {DataService} from '../../utils/data.service';
import {FileChangeEvent} from "@angular/compiler-cli/src/perform_watch";
import {Thesaurus} from "../../thesauri/thesaurus";

@Component({
  selector: 'app-root',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.less'],
  encapsulation: ViewEncapsulation.None //prevent encapsulation of css definitions @ee https://angular.io/api/core/ViewEncapsulation
})
export class AdminComponent extends Page {
  slug = 'admin';

  private username: string;
  private password: string;
  private loggedIn = false; //used to switch the containers after the log in

  private articles: {id, highlight, title, locale}[] = []; //the articles list
  private articlesFound = false; //used to switch the containers once the articles are loaded
  private products: {id, name}[]; //the products list
  private languages: {locale, label}[]; //the languages list
  private form = {
    id: '', //hidden; used to differentiate existing articles from new ones
    active: false,
    product: '',
    locale: '',
    alias: '',
    image: '',
    image_title: '',
    title: '',
    quote: '',
    review: '',
    homepage: '',
    priority_top: '',
    priority_locale: '',
    date_publication: ''
  };
  private image: File;
  private notification: string; //the notification area's content


  constructor(
    protected language: LanguageService, protected http: HttpClient,
    public data: DataService
  ){
    super(language, http);
    //SHOULD NOT SWITCH; th language is implicitly set in the line above

    //the placeholders
    this.products = [{id: '', name: this.language.theo.product}];
    this.languages = [{locale: this.language.theo.slug, label: this.language.theo.language}];
    this.form.image = Thesaurus._; //unknown flag
  }

  ngOnInit(): void {
    super.ngOnInit();
    //const administrator = document.getElementById('articles_administrator')
    //this.list = administrator.querySelector('section:nth-child(2)')
    //this.formJS = administrator.querySelector('section:last-child')

    if(!DataService.prodMode){ //this block is used to debug only
      this.username = 'sigma_sport_micro_site_admin';
      this.password = 'AAw_VE4F+5BWT?1XeSpP2kdyg6AkJkHrO1';
      this.login()
    }
  }

  /**
   * this function is used to login
   */
  login(): void {
    this.fetch(
      {action: 'login', username: this.username, password: this.password},
      data => { //this block is only reachable if the validation was successful
        //empty the access_controller
        this.username = '';
        this.password = '';

        console.log(data)

        //load the articles
        if(data.articles.length > 0){
          data.articles.forEach(article => article.title = this.label(article));
          this.articles = data.articles;
          this.articlesFound = true;
        }

        //load the products & languages
        this.products = this.products.concat(data.products);
        this.languages = this.languages.concat(data.languages);

        //DO NOT SWITCH
        this.loggedIn = true; //switch occurs after this attribute is updated by using the 2-way bidding function provided by Angular
      }
    )
  }

  /**
   * this function is used to load the given article
   * @param id the identifier of the article in question
   */
  loadArticle(id: string): void {
    this.notification = ''; //empty the notification area
    this.fetch(
      {action: 'load_article', id: id},
      data => { //TRUE if the article was successfully loaded
        //console.log(data)
        //pre-process
        data.active = data.active == 1; //SQL boolean to JS boolean
        data.image_title = data.image;
        data.image = data.image ? DataService.preview(data.image, 30, '') : Thesaurus._; //unknown flag
        this.image = null;

        for(let key in data) if(key in this.form) this.form[key] = data[key] || ''
      }
    )
  }

  /**
   * this function is used to delete the given article
   * @param article s.e.
   * @param event the click event
   */
  deleteArticle(article: {id, title, locale}, event: Event): void {
    if(confirm(`${this.language.theo.deletion_confirmation} ${article.title}`)) this.fetch(
      {action: 'delete_article', id: article.id},
      //@ts-ignore
      _ => { //TRUE if the deletion went as expected
        delete this.articles[article.id]
        //@ts-ignore
        this.elt(event).parentElement.remove()
      }
    )
  }

  /**
   * ``ngModel`` seems not to be useable by ``input[type=file]`` elements, it works but returns a security breach error on load; hence the work around below
   * this is a wrapper function; it is used to visualise the changes made by the user
   * @see https://medium.com/@amcdnl/file-uploads-with-angular-reactive-forms-960fd0b34cb5
   * @param event
   */
  updateFileUploader(event: FileChangeEvent | Event){
    // @ts-ignore
    this.image = event.target.files[0];
    this.form.image = this.image.name;

    // @ts-ignore
    //console.log(event.target.files)
  }

  /**
   * this function is used to either load or delete the given article
   * @since PM (15.11.2019) @deprecated I'm going full Angular now :-D
   * @param event
   */
  handleArticle(event: Event): void {
    this.notification = ''; //empty the notification area

    // @ts-ignore
    const elt = this.elt(event), classList = elt.classList;
    if(classList.contains('label')){ //TRUE if the article should be loaded
      this.fetch(
        // @ts-ignore
        {action: 'load_article', id: elt.getAttribute('data-id')},
        data => { //TRUE if the article was successfully loaded
          let elt;
          //for (let key in data) if( (elt = this.formJS.querySelector(`[name=${key}]`)) ) elt.value = data[key]
        }
      )
    }else if(classList.contains('close')){ //TRUE if the article should be deleted
      //DO NOT MERGE
      // @ts-ignore
      if(confirm(`${this.language.theo.deletion_confirmation} ${elt.previousElementSibling.innerHTML}`)) this.fetch(
        // @ts-ignore
        {action: 'delete_article', id: elt.getAttribute('data-id')},
        //@ts-ignore
        _ => elt.parentElement.remove() //TRUE if the deletion went as expected
      )
    }
  }

  /**
   * this function is used to empty the form
   */
  empty(): void {
    this.notification = ''; //empty the notification area

    //pre-process
    this.form.active = false;
    this.form.image = Thesaurus._; //unknown flag
    this.image = null;

    //the rest
    for(let key in this.form) if(key != 'active' && key != 'image') this.form[key] = ''
  }


  /**
   * this function is used to save the data residing in the form, in the database;
   * either via an insert or an update
   * @see https://www.taniarascia.com/how-to-upload-files-to-a-server-with-plain-javascript-and-php/
   *      https://developer.mozilla.org/en-US/docs/Web/API/FormData
   *      https://developer.mozilla.org/en-US/docs/Web/API/Element/insertAdjacentHTML
   */
  save(): void {
    this.notification = ''; //empty the notification area

    //collect the data
    const data = new FormData();
    data.append('action', 'save_article');
    data.append('display', this.form.active ? '2' : '1');
    data.append('image', this.image);
    for(let key in this.form) if(key != 'active' && key != 'image') data.append(key, this.form[key]);

    this.fetch(
      data,
      article => { //TRUE if everything went as expected
        for(let key in article) if(key in this.form) this.form[key] = article[key] || ''; //update the values

        let i;
        if(article.update){
          try{
            this.articles.forEach((article2, index) => {
              if(article2.id == article.id){
                article2.title = this.label(article);
                i = index;
                throw 'update_done'
              }
            })
          }catch(e){ if(e != 'update_done') throw e } //only the flag
        }else if(article.insert){ //insert at the top
          //this.list.insertAdjacentHTML('afterbegin', this.article(article)) //add at the top
          this.articles.splice(i = 0,0, article)
        }

        if(i !== undefined){ //highlight the article in the list
          this.articles[i].highlight = true;
          setTimeout(
            (article, notification) => {
              article.highlight = false;
              notification = ''
              //this.form.querySelectorAll('*[name]').forEach(elt => elt.value = ''); //empty the form
            },
            2000,
            this.articles[i], this.notification
          )
        }

        this.notification = article.warning ? this.language.theo.save_notification_warning : this.language.theo.save_notification; //notify the user
      }
    )
  }

  private label(article: {title, locale}): string { return `${article.title} // ${article.locale}` }

  private article(article: {id, title, locale}): string {
    return `<div class="article" data-id="${article.id}">
      <span class="label" data-id="${article.id}">${this.label(article)}</span>
      <span class="close" data-id="${article.id}">&times;</span>
    </div>`
  }
}
