import {Component, OnInit, ViewEncapsulation, HostListener, Inject} from '@angular/core';
import {DOCUMENT} from "@angular/common";
import {HttpClient} from "@angular/common/http";
import { ActivatedRoute } from "@angular/router";
import { DataService } from "../../utils/data.service";
import {LanguageService} from "../../utils/language.service";
import {Page} from "../../utils/Page";


declare const $: any;
@Component({
    selector: 'app-root',
    templateUrl: './articles.component.html',
    styleUrls: ['./articles.component.less'],
    encapsulation: ViewEncapsulation.None //prevent encapsulation of css definitions @ee https://angular.io/api/core/ViewEncapsulation
})
export class ArticlesComponent extends Page implements OnInit {
  protected admin = 0;

  private article: any;
  private container;
  private clipboard;
  private all_articles_loaded = false; //TRUE if all the articles have been loaded
  private scroll_loading = false; //TRUE if the articles are been loaded via scroll


  constructor(
    protected language: LanguageService, protected http: HttpClient, private route: ActivatedRoute,
    public data: DataService,
    @Inject(DOCUMENT) private document: Document
  ){ super(language, http) }


  ngOnInit(): void {
    super.ngOnInit();

    this.article = this.route.snapshot.paramMap.get("article"); //NULL if undefined
    //console.log(`[[${this.article}]]`);

    this.fetch(
      {action: "start", admin: this.admin, article: this.article},
      data => this.data.set(data, this.language) //TRUE if the server request was successfully parsed
    );

    this.container = document.getElementById("articles_all_container");
    this.clipboard = document.getElementById('clipboard_proxy');
  }

  /**
   * this function is the equivalent of jQuery.document.ready
   * it is implicitly invoked once the DOM has been fully loaded
   * @see https://angular.io/api/core/AfterViewInit
   */
  ngAfterViewInit(): void {
    super.ngAfterViewInit();

    const interval = setInterval( //setInterval is required because this.fetch runs asynchronously
      () => {
        if(this.done){ //TRUE if the request to the server is complete (positive/negative response or error)
          //DO NOT MERGE
          if(!this.error){
            if(this.data.article){ //article / to focus on
              const article = this.data.article;

              let socials = "";
              article.socials.forEach(social => socials += `<a href="${social.href}" target="_blank"><i class="${social.fa}"></i></a>`);

              this.openModal(
                this.language.theo.headline2,
                `<div class="article article-fullscreen">
                  <div class="h-100 img-box-center img-box-color">
                    <img class="img-fluid" src="${article.image}" alt="">
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <h2 class="card-title col-md-8">${article.title}</h2>
                      <div class="col-md-4 date">${article.date}</div>
                      <div class="col-md-4 socials">${socials}</div>
                    </div>
                    <p class="card-text">${article.fullview}</p>
                  </div>
                </div>`
              );
            }
            //DO NOT MERGE
            this.filter() //activate all languages at the beginning
          }

          clearInterval(interval)
        }
      },
      500
    )
  }

  /**
   * @see https://brianflove.com/2016/10/10/angular-2-window-scroll-event-using-hostlistener/
   * @see https://stackoverflow.com/a/38252057/2652918
   */
  @HostListener("window:scroll", ['$event.target'])
  onWindowScroll() {
    //console.log(window.innerHeight, window.scrollY, this.document.documentElement.scrollTop, this.document.body.scrollTop, this.document.documentElement.offsetHeight, this.document.body.offsetHeight, this.document.documentElement.scrollHeight, this.document.body.scrollHeight)
    if(this.done && this.document.documentElement.scrollHeight - window.innerHeight - window.scrollY <= 100 && !this.scroll_loading){
      this.scroll_loading = true; //set the lock
      this.loadMore(false);
    }
  }

  /**
   * this function is used to copy the slug to the clipboard
   * @param alias {string}
   */
  copyToClipboard(alias: string){
    const url = this.url(alias)
    this.clipboard.value = url
    this.clipboard.select()
    document.execCommand("copy");
    alert(`${this.language.theo.copied}:\n${url}`)
  }

  /**
   *
   * @param event Event the click event
   */
  readMore(event: Event){
    //console.log(event)
    const elt = $(event.target);
    if(elt.hasClass("read-more")){
      const
        article = elt.parents('div.article'),
        homepage = article[0].querySelector('a.homepage')
      ;
      this.openModal(
        this.language.theo.headline2,
          `<div class="article article-fullscreen">
            <div class="h-100 img-box-center img-box-color">
              <img class="img-fluid" src="${article.find('img.img-fluid').attr('src')}" alt="">
            </div>
            <div class="card-body">
              <div class="row">
                <h2 class="card-title col-md-8">${article.find('.card-title').html()}</h2>
                <div class="col-md-4 date">${article.find('div.date').html()}</div>
                <div class="col-md-4 socials">${article.find('div.socials').html()}</div>
              </div>
              <p class="card-text">${article.find('p.card-text.fullview').html()}</p>
              ${homepage ? `<a class="btn btn-outline-danger sigma-white-red homepage" href="${homepage.getAttribute('href')}" target="_blank">${this.language.theo.homepage}</a>` : ''}
            </div>
          </div>`
      )
      //${homepage ? `<a class="btn btn-danger text-white homepage" href="${homepage.getAttribute('href')}" target="_blank">${this.language.theo.homepage}</a>` : ''}


      /* debugger
      const article = elt.parents('div.article')
      this.openModal('', article[0].outerHTML)
      */
    }
  }

  /**
   * this function is used to filter the entries based on the list of active languages
   */
  filter(){
    const languages = {};
    this.data.languages.forEach(language => languages[language.locale] = language.active) //set the list of active languages

    this.data.articles_all.forEach(article => article.active = languages[article.locale]) //update the visibility of the articles
  }


  /**
   * this function is used to load additional articles
   * @param scroll boolean TRUE if the scroll should be invoked once all the articles will have been loaded
   */
  loadMore(scroll: boolean){
    if(!this.all_articles_loaded) this.fetch(
      {action: "load_more", admin: this.admin, id: this.data.id, offset: this.data.offset, locales: this.data.locales},
      data => {
        if(data.articles.length == 0) this.all_articles_loaded = true;
        else{ //TRUE if the server request was successfully parsed
          DataService.transfer(data.articles, this.data.articles_all, this.language);

          this.filter(); //set the active flag on the newly added article whose language is currently displayed
          this.data.offset = data.offset;

          //MUST COME AFTER `this.filter()`
          if(scroll) setTimeout( //used to give enough time to the AngularDOM to be done
            articles => {
              for(let i = 0; i < articles.length; i++){
                const elt = document.querySelector(`div.article.article-all.${this.active}[data-id='${articles[i].id}']`); //the first (visible) of the newly added articles
                if(elt){
                  this.scroller.animate({scrollTop: $(elt).offset().top}) //scroll to the first (visible) of the newly added articles
                  break; //break the loop after the first finding
                }
              }
            },
            500,
            data.articles
          );
          else this.scroll_loading = false; //release the scroll loading lock
        }
      }
    )
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.less'],
  encapsulation: ViewEncapsulation.None //prevent encapsulation of css definitions @ee https://angular.io/api/core/ViewEncapsulation
})
export class ArticlesAdminComponent extends ArticlesComponent { admin = 1 }
