import {Component, Input, ViewEncapsulation} from '@angular/core';
import {Section} from '../../utils/Section';
import * as $ from 'jquery';

@Component({
  selector: 'section-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.less'],
  encapsulation: ViewEncapsulation.None //prevent encapsulation of css definitions @ee https://angular.io/api/core/ViewEncapsulation
})

/**
 * @see https://codecraft.tv/courses/angular/quickstart/nesting-components-and-inputs/
 */
export class NavComponent extends Section {
    slug = 'nav';
}
