import { Component } from '@angular/core';
import {Thesaurus} from "./thesauri/thesaurus";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less']
})
export class AppComponent {
  title = Thesaurus.project;
}
