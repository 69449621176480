import {Component, Input} from '@angular/core';
import {Section} from '../../utils/Section';

@Component({
  selector: 'section-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.less']
})

/**
 * @see https://codecraft.tv/courses/angular/quickstart/nesting-components-and-inputs/
 */
export class ModalComponent extends Section {
  slug = 'modal';
  @Input() title = '';
  @Input() body = '';
  //@Input() title = Thesaurus._;
  //@Input() body = Thesaurus._;
}
