import { Injectable } from '@angular/core';
import {Thesaurus} from "./thesaurus";
import {DataService} from "../utils/data.service";

@Injectable({
    providedIn: 'root',
})

/**
 * PM (21.06.219)
 * this service is used to separately (thus cleanly) handle the texts
 */
export class ThesaurusEN extends Thesaurus {
  slug = 'en';
  label = 'English';
  //...
  optional = 'optional';
  //...
  alias = `Alias (${this.optional})`;
  alias_title = 'Enter the word that should be used to build the speaking URL. If missing the ID will be used instead.';
  articles_all =  'All press reviews';
  articles_top = 'Top 3 reviews';
  awards = 'Awards';
  copied = 'Copied';
  close = 'Close';
  date_publication = 'Close';
  deletion_confirmation = 'You are about to delete the following article:';
  empty = 'Empty the form';
  error = 'Server error';
  errors = {
    action_missing: 'Die Aktion fehlt.',
    access_denied: 'Access data denied. Your access data are invalid.',
    access_data_empty: 'The username or the password is empty.',
    update_failed: 'The article couldn\'t be updated.',
    insert_failed: 'The article couldn\'t be inserted.',
    id_missing: `The id is missing.`,
    locales_missing: `The locales are missing.`,
    start_missing: `The start is missing.`
  };
  headline = 'ROX 12.0 SPORT<br>International media response';
  headline2 = this.headline.replace('<br>', ' // ')
  homepage = 'Full article';
  homepage_admin = `${this.homepage} (${this.optional})`;
  image = `Image (${this.optional})`;
  language = 'Language';
  languages = {};
  load_more = 'Load more';
  login = 'Login';
  meta = [
    {
      label: "Imprint",
      href: `${DataService.company_url}/${this.slug}/impressum`
    },
    {
      label: "Data Privacy",
      href: `${DataService.company_url}/${this.slug}/datenschutz`
    }
  ];
  months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  no_articles = 'No articles!';
  password = 'Password';
  priority_top = `Priority (${this.articles_top.toLowerCase()}, ${this.optional})`;
  priority_top_title = "You should use letters of the regular english alphabet: 'a' to 'z'.";
  priority_locale = `Priority (${this.language.toLowerCase()}, ${this.optional})`;
  priority_locale_title = this.priority_top_title +"\nThe priority in this case is done by language first.";
  product = 'Product';
  preview = 'Preview';
  publish = 'Publish';
  publish_title = 'Check this box to publish the post.';
  quote = 'Quote';
  read_more = 'Read more';
  review = 'Review (optional)';
  save = 'Save the data';
  save_notification = 'The data have been saved.';
  save_notification_warning = this.save_notification +' <strong>However the image could not be uploaded.</strong>';
  socials = [
    {
      slug: "blog",
      href: "https://sigma-inmotion.com/"+ this.slug
    },
    {
      slug: "facebook",
      href: "https://www.facebook.com/SIGMASPORTGermany"
    },
    {
      slug: "instagram",
      href: "https://www.instagram.com/sigma_sport_germany/"
    },
    {
      slug: "youtube",
      href: "https://www.youtube.com/user/SIGMAelektro"
    },
  ];
  title = 'Title';
  username = 'Username';
}
