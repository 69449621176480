import {Injectable, OnInit} from '@angular/core';
import {Thesaurus} from '../thesauri/thesaurus';
import {ThesaurusEN} from '../thesauri/thesaurusEN';
import {Title} from '@angular/platform-browser';
import {Slugify} from "./Slugify";

@Injectable({
    providedIn: 'root',
})
export class LanguageService implements Slugify /*, OnInit*/ {
  slug = 'language';
  private readonly default = 'en';
  readonly thesauri = {
    en: new ThesaurusEN, //PM (24.07.2019)

    /** @todo add the other thesauri here */
  };
  readonly langs = Object.keys(this.thesauri);
  theo: Thesaurus = this.thesauri[this.default];


  /**
   * this function is used to initialise the language & thesaurus
   * @see https://stackoverflow.com/questions/36914151/browser-language-detection?noredirect=1&lq=1 # navigator.language
   */
  constructor(private titleService: Title){ this.titleService.setTitle(Thesaurus.project) }
}
