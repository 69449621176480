import {Component} from '@angular/core';
import {Section} from '../../utils/Section';

@Component({
  selector: 'section-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})

/**
 * @see https://codecraft.tv/courses/angular/quickstart/nesting-components-and-inputs/
 */
export class FooterComponent extends Section {
  slug = 'footer';
}

