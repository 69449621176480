import { Injectable } from '@angular/core';
import {LanguageService} from "./language.service";

@Injectable({
    providedIn: 'root',
})

/**
 * PM (21.06.219)
 * this service is used as proxy to avoid errors during the initialisation before the values are fetched from the server
 */
export class DataService {
  static readonly devFlag = 'srv05.24743.serviceprovider.de';
  static readonly domain = 'sigmasport.com';
  static readonly prodMode = window.location.hostname.includes(DataService.domain.split('.')[0]) && !window.location.href.includes(DataService.devFlag); //TRUE if local || staging
  static readonly company_url = 'https://'+ DataService.domain + (window.location.href.includes(DataService.devFlag) ? '.'+ DataService.devFlag : '');
  static readonly company = 'SIGMA SPORT';
  static readonly copyright = `&copy; ${DataService.company} ${new Date().getFullYear()}`;

  private sep: any;
  private static readonly fas = {instagram: "fab fa-instagram", facebook: "fab fa-facebook", homepage: "fas fa-globe"};
  private static readonly socials = Object.keys(DataService.fas);

  id: any;
  offset = 0;
  product = {label: "", slug: ""};
  awards = [];
  //articles_top = [];
  articles_top: {active, id, locale, alias, image, title, preview, fullview, date, homepage, socials, read_more: boolean}[] = [];
  languages: {label, locale, active}[] = [];
  locales = [];
  //articles_all = [];
  articles_all: {active, id, locale, alias, image, title, preview, fullview, date, homepage, socials, read_more: boolean}[] = [];
  article: any;


  //constructor(){}

  /**
   * this function is used to set/fill the various objects required in the frontend
   * @param data
   * @param language
   */
  set(data, language: LanguageService): boolean {
    this.sep = data.sep;
    //DO NOT SWITCH

    //product
    this.id = data.id;
    this.offset = data.offset; //load more: next block

    this.product = data.product;
    this.product["slug"] = this.slug(data.product.label);

    //awards
    data.awards.forEach( award => this.awards.push(this.slug(award)) );

    //articles / top
    //data.articles_top.forEach(article => this.articles_top.push(DataService.finalise(article, language)) );
    DataService.transfer(data.articles_top, this.articles_top, language);

    //languages
    data.languages.forEach( language => language.active = true);
    this.languages = data.languages;
    this.locales = data.locales;

    //articles / all
    //data.articles_all.forEach( article => this.articles_all.push(DataService.finalise(article, language)) );
    DataService.transfer(data.articles_all, this.articles_all, language);
    //console.log(data.articles_all);
    //article / to focus on
    if('article' in data) this.article = DataService.finalise(data.article, language);

    return true
  }

  /**
   *
   * @param article
   * @param language
   */
  static finalise(article: {id, locale, alias, image, title, quote, review, date, homepage}, language: LanguageService): {active, id, locale, alias, image, title, preview, fullview, date, homepage, socials, read_more: boolean}{
    /*console.log(
      article.quote,
      '--------------------',
      article.review,
      (article.review as string).includes(article.quote.replace(/\.+$/, ''))
    )*/
    let preview = DataService.preview(article.quote, 20), fullview:string, readMore: boolean;
    if(article.review == '' || article.review == article.quote){
      fullview = article.quote;
      readMore = preview != article.quote;
    }else{
      fullview = (article.review as string).includes(article.quote.replace(/\.+$/, '')) ?
        article.review :
        `<em>${article.quote}</em><br><span>${article.review}</span>`
      ;
      readMore = true;
    }

    const date = article.date.split('-'); //year, month[, day]

    return {
      active: false, //PM (19.11.2019)
      id: article.id,
      locale: article.locale,
      alias: article.alias || article.id,
      image: article.image,
      title: DataService.preview(article.title, 10),
      preview: preview,
      fullview: fullview,
      date: language.theo.months[Number(date[1]) - 1] +' '+ date[0],
      homepage: article.homepage || '',
      socials: DataService.getSocials(article),
      read_more: readMore
    }
  }

  /**
   * this function is used to parse & store the articles from their raw - to their final -format
   * @param raw the raw articles
   * @param list the list of the final articles
   * @param language
   */
  static transfer(raw: {id, locale, alias, image, title, quote, review, date, homepage}[], list: {active, id, locale, alias, image, title, preview, fullview, date, homepage, socials, read_more: boolean}[], language: LanguageService){
    raw.forEach( article => list.push(DataService.finalise(article, language)) );
  }

  /**
   *
   * @param text
   * @param amOfWords Number 16 is the empiric default value
   * @param sep
   */
  static preview(text: string, amOfWords: number = 16, sep: string = ' '): string {
    text = text.trim();
    const words = text.split(sep);

    return words.length > amOfWords ? words.slice(0, amOfWords - 2).join(sep) +"..." : text
  }

  /**
   * this function is used to get the social block
   * @param article
   */
  private static getSocials(article){
    const socials = []
    DataService.socials.forEach(social => {
      if(article[social]) socials.push( {fa: DataService.fas[social], href: article[social]} )
    })

    return socials
  }

  private slug(label: string): string { return label.trim().toLowerCase().replace(/[\W]+/g, this.sep || "_") }
}
