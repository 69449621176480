import {DataService} from "../utils/data.service";
import {Slugify} from "../utils/Slugify";

export class Thesaurus implements Slugify {
  static readonly _ = '???';
  static readonly project = DataService.company +' // Press';
  //...
  slug: string;
  label: string;
  //...

  alias: string;
  alias_title: string;
  articles_top: string;
  articles_all: string;
  awards: string;
  copied: string;
  close: string;
  date_publication: string;
  deletion_confirmation: string;
  empty: string;
  error: string;
  errors: {action_missing:string, access_denied:string, access_data_empty:string, update_failed:string, insert_failed:string, id_missing:string, locales_missing:string, start_missing:string };
  headline: string;
  headline2: string;
  homepage: string;
  homepage_admin: string;
  image: string;
  language: string;
  languages: {};
  load_more: string;
  login: string;
  meta: {label, href}[];
  months: string[];
  no_articles: string;
  optional: string;
  password: string;
  priority_locale: string;
  priority_locale_title: string;
  priority_top: string;
  priority_top_title: string;
  product: string;
  preview: string;
  publish: string;
  publish_title: string;
  quote: string;
  read_more: string;
  review: string;
  save: string;
  save_notification: string;
  save_notification_warning: string;
  socials: {slug, href}[];
  title: string;
  username: string;
}
